import React from "react"
import { Link } from "gatsby"

import PageWrapper from "../components/PageWrapper"
import Section from "../components/Section"
import Button from "../components/Button"
import Container from "../components/Container"
import SectionTitle from "../components/SectionTitle"
import SectionText from "../components/SectionText"
import SuccessImage from "../images/svg/success.svg"

const Success = () => (
  <PageWrapper
    footerConfig={{
      hasMiddleSocial: true,
      bottomWrapperClassName: "!justify-center",
      bottomClassName: "bg-primary-25",
      className: "bg-primary-25",
    }}
  >
    <Section isHero>
      <Container>
        {/* wrap */}
        <div className="flex flex-col items-center justify-center laptop:flex-row laptop:gap-[180px] gap-14">
          {/* left-part */}
          <div className="">
            <div className="flex flex-col">
              <SectionTitle isHero={true} className="!pb-2 !font-bold">
                Success!
              </SectionTitle>
              <h6 className="pb-4 font-medium laptop:pb-6 text-neutral-900 text-display-sm">
                The action is completed
              </h6>
              <SectionText isHero={true} className="max-w-[304px]">
                Demo success message. You can change according to your need.
              </SectionText>
              <Link to="#">
                <Button className="" size={"2xl"}>
                  Go to home
                </Button>
              </Link>
            </div>
          </div>
          <div>
            <SuccessImage className="w-full h-auto tablet:w-auto" />
          </div>
        </div>
      </Container>
    </Section>
  </PageWrapper>
)

export default Success
